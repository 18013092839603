import React from "react";
import { Link } from "react-router-dom";
//images
import headshotIcon from "../img/headshotIcon.png";
import emailIcon from "../img/emailMarketingIcon.png";
import lynx from "../img/lynx.jpg";

//components
import ContactForm from "./contactForm";
import Testimonials from "./testimonials";

const EmailMarketing = () => {
 
  return (
    <div className="servicesContainer emailMarketingContainer">
      <div className="mobileImage">
          <img src={lynx} alt="lynx" />
        </div>
    <div className="servicesInner">
      <div className="servicesPageFeature pageBody">
      <div className="servicesPageLeft">
      <h1>Email Marketing</h1>
      <p>In today's fast-paced digital landscape, email marketing remains a powerful channel for fostering lasting connections with your audience. We harness the potential of email marketing to create genuine and personalized interactions with your subscribers.</p>

      <p>Email marketing is about building meaningful relationships. We work closely with you to understand your brand's goals, target demographics, and messaging objectives. With this insight, we craft and deploy email campaigns that engage, inform, and inspire action. Our strategies focus on compelling subject lines, valuable content, and clear calls to action, all aimed at driving results.</p>
      
      <p>Whether you're looking to increase brand awareness, boost sales, or nurture leads, our data-driven approach ensures effective campaigns that build brand loyalty, enhance engagement, and drive conversions.</p>

      <Link to="/contact"><p className="button">Contact Us</p></Link>

      </div>
      <div className="servicesPageRight">

      </div>
      </div>
      
      <div className="servicesIndividualBlocks">
        <div className="pageBody">
        <div className="servicesIndividualRow">
          <div className="servicesIndividualLeftBlock">
            <img src={headshotIcon} alt="headshot icon" className="servicesIcon" />
            <h2>Knowing Your Audience</h2>
            <p>Effective communication is about understanding who you're speaking to and how your message resonates with them. Recognising the pivotal role of audience-centric communication is an essential part of our email marketing strategies.</p>

            <p>Our approach to tailoring communication begins with a thorough understanding of your target audience. We delve deep into their demographics, preferences, behaviors, and pain points. Whether you're addressing customers, investors, or stakeholders, we work closely with you to create audience personas that encompass the various aspects of your audience's identity.</p>

            <p>With this audience insight, we design messaging that speaks directly to the needs and interests of your designated audience segments. Whether it's adjusting the tone, choice of words, or the related imagery, we ensure that your message is both relevant and compelling. Our commitment extends to crafting content that resonates with your audience, delivering value, and fostering trust. We recognise that one size doesn't fit all, and our strategies are adaptable to cater to the diverse expectations and preferences of your audience segments.</p>

          </div>
          <div className="servicesIndividualRightBlock">
          <img src={emailIcon} alt="email marketing icon" className="servicesIcon" />
          <h2>Crafting Campaigns</h2>
            <p>Whether you're looking to promote a new product, share valuable content, or nurture leads, we work closely with you to define the scope and messaging strategy. With this foundation, we design visually appealing and responsive email templates that align with your brand's visual identity.</p>

            <p>Compelling subject lines are vital to getting your emails noticed, and our team takes special care to craft engaging and relevant subjects that encourage recipients to open and read your message. We focus on delivering content that not only informs but also captivates the reader, providing value and addressing their needs. Whether it's creating an engaging narrative, incorporating eye-catching visuals, or providing clear calls to action, our goal is to inspire recipients to take the desired action, whether it's making a purchase, signing up for a newsletter, or requesting more information.</p>

            <p>The success of an email marketing campaign extends beyond the send button. Our team monitors key performance indicators, such as open rates, click-through rates, and conversion metrics, to measure the effectiveness of each campaign. With this data-driven approach, we continuously optimise and refine your email marketing strategy to ensure that it remains efficient and impactful.</p>

          </div>
        </div>
        </div>
      </div>
      <div className="homeContactContainer servicesContact">
      <div className="homeContactGrid pageBody">
        <div className="homeContactLeft">
          <h2>Contact Us</h2>
        <ContactForm />
        </div>
      <div className="homeContactRight">
        <h2>Testimonials</h2>
        <Testimonials />
      </div>
    </div>
    </div>
      </div>
      </div>
  );
};

export default EmailMarketing;


