import React from "react";
import { Link } from "react-router-dom";

//images
import wordpressIcon from "../img/wordpressIcon.png";
import codeIcon from "../img/codeIcon.png";

//components
import ContactForm from "./contactForm";
import Testimonials from "./testimonials";


const Websites = () => {
 
  return (
    <div className="servicesContainer websitesContainer">
    <div className="servicesInner">
      <div className="servicesPageFeature pageBody">
      <div className="servicesPageLeft">
      <h1>Websites</h1>
      <p>we're dedicated to delivering tailored web solutions that cater to a diverse range of needs. Our approach encompasses both WordPress CMS builds and custom React web development. For those seeking user-friendly content management and easy updates, we offer WordPress-based websites. With its versatile range of themes and plugins, WordPress empowers our clients to take control of their digital content effortlessly.</p>

      <p>For those who require a more dynamic, feature-rich web presence, our custom React builds deliver. These websites harness the power of React.js, providing interactive user interfaces and swift loading times. Each project is approached with adaptability, ensuring we select the best technology stack to align with the unique goals and objectives of our clients. Our commitment is to provide websites that leave a lasting impression and deliver results, whether through the familiar and user-friendly WordPress CMS or the custom-built, cutting-edge experiences of React. Your vision, our expertise - together, we craft digital excellence.</p>

      <Link to="/contact"><p className="button">Contact Us</p></Link>

      </div>
      <div className="servicesPageRight">

      </div>
      </div>
      
      <div className="servicesIndividualBlocks">
        <div className="pageBody">
        <div className="servicesIndividualRow">
          <div className="servicesIndividualLeftBlock">
            <img src={wordpressIcon} alt="wordpress icon" />
            <h2>WordPress CMS Builds</h2>
            <p>WordPress is a cornerstone of our web development approach, offering a robust content management system that empowers our clients with user-friendly control over their digital content. With an extensive array of themes, plugins, and a straightforward dashboard, WordPress is the go-to solution for those seeking efficient content updates and a seamless management experience. It's a platform that has stood the test of time, known for its versatility, scalability, and its ability to cater to a wide spectrum of web projects, from simple blogs to intricate e-commerce websites.</p>

            <p>We specialise in harnessing the full potential of WordPress to create websites that not only look exceptional but also offer a superior user experience. From responsive designs to seamless navigation, our WordPress websites are meticulously crafted to cater to the modern expectations of web design. Whether you're looking to establish an online presence, revamp your existing website, or simply make content updates a breeze, our WordPress CMS builds are your reliable path to a successful digital presence. Your content, your control - let us elevate your WordPress web experience.</p>
          </div>
          <div className="servicesIndividualRightBlock">
          <img src={codeIcon} alt="code icon" className="servicesIcon" />
          <h2>Custom React Builds</h2>
            <p>When it comes to web development, we recognize that one size doesn't fit all. That's why we offer custom React builds, designed to provide dynamic, feature-rich web experiences that go beyond traditional boundaries.</p> 
            
            <p>React.js is at the core of our custom web development, enabling us to create interactive user interfaces and lightning-fast loading times.</p>

            <p>Our approach to custom React builds is rooted in adaptability. We understand that every project is unique, and React allows us to tailor our solutions precisely to your specific requirements.</p> 
            
            <p>Whether you're in need of a modern, streamlined website, a sophisticated web application, or a progressive web app, our team has the expertise to make it a reality. With custom React builds, you can expect cutting-edge web solutions that leave a lasting impression and keep your audience engaged. Your digital future, powered by React - let's create web experiences that redefine possibilities.</p>
          </div>
        </div>
        </div>
      </div>
      <div className="homeContactContainer servicesContact">
      <div className="homeContactGrid pageBody">
        <div className="homeContactLeft">
          <h2>Contact Us</h2>
        <ContactForm />
        </div>
      <div className="homeContactRight">
        <h2>Testimonials</h2>
        <Testimonials />
      </div>
    </div>
    </div>
      </div>
      </div>
  );
};

export default Websites;


