import React from "react";
import { Link } from "react-router-dom";

//images
import brandIcon from "../img/micIcon.png";
import puzzleIcon from "../img/puzzleIcon.png";
import monkey from "../img/monkey.jpg";

//components
import ContactForm from "./contactForm";
import Testimonials from "./testimonials";

const Branding = () => {
 
  return (
    <div className="servicesContainer brandingContainer">
      <div className="mobileImage">
          <img src={monkey} alt="monkey" />
        </div>
    <div className="servicesInner">
      <div className="servicesPageFeature pageBody">
      <div className="servicesPageLeft">
      <h1>Branding</h1>
      <p>Branding is at the heart of every business, defining not just what you offer but who you are. We specialise in the art of brand transformation and strategy, guiding businesses to create a unique and compelling identity. We believe that a brand is more than just a logo; it's a story, a promise, and a reflection of your core values.</p>

      <p>Our approach begins with a deep understanding of your business, target audience, and market landscape. We work closely with you to define your brand essence, encapsulating the very core of your identity. From crafting an impactful brand name to designing a distinctive visual identity and developing a compelling brand narrative, we are dedicated to creating a brand that resonates with your audience and sets you apart from the competition. </p>

      <Link to="/contact"><p className="button">Contact Us</p></Link>

      </div>
      <div className="servicesPageRight">

      </div>
      </div>
      
      <div className="servicesIndividualBlocks">
        <div className="pageBody">
        <div className="servicesIndividualRow">
          <div className="servicesIndividualLeftBlock">
            <img src={brandIcon} alt="Branding Icon" className="servicesIcon"/>
            <h2>Brand Design</h2>
            <p>Designing a brand is more than just creating a visual identity; it's about crafting an emotional connection with your audience. It's a meticulous art that shapes the visual elements and aesthetics that represent your business.</p>

            <p>Our brand design team is passionate about creating memorable visual identities that resonate with your target market. We delve deep into understanding your brand's personality, values, and unique selling points. This knowledge serves as the foundation for creating a visual identity that tells a story, evokes emotions, and stands out in a crowded marketplace. We take every detail into consideration - from selecting the right colour palette, typography, and imagery to defining the brand logo and design elements. Each component is thoughtfully chosen to create a cohesive and impactful brand design that visually communicates your brand's essence.</p>

            <p>Our design process involves extensive research and exploration, leading to the creation of logo designs, visual guidelines, and brand assets that are not only aesthetically pleasing but also strategically aligned with your business objectives. We focus on creating a brand design that is versatile and timeless, ensuring that it can adapt to various applications and mediums while maintaining its integrity. The result is a visual identity that not only captivates your audience but also forms a strong foundation for brand recognition and trust.</p>

          </div>
          <div className="servicesIndividualRightBlock">
          <img src={puzzleIcon} alt="puzzle piece icon" className="servicesIcon" />
          <h2>Brand Strategy</h2>
            <p>Crafting a brand strategy is about defining the roadmap that guides your brand's journey and interactions with the world. Brand strategy is the cornerstone of our approach. We're dedicated to creating a compelling narrative that connects your brand with its audience.</p>

            <p>We excel at understanding business objectives, market dynamics, and target audience. We dive deep into your brand's DNA to unearth its core values, mission, and unique selling propositions. With this foundation, we work with you to develop a brand strategy that defines your positioning, messaging, and goals. Our approach encompasses the creation of a robust brand architecture, including brand mission and vision statements, brand promise, and brand personality traits. We're dedicated to creating a strategy that not only reflects your brand essence but also resonates with your audience, setting the stage for enduring brand loyalty and recognition.</p>

            <p>The development of your brand strategy is a collaborative process. We engage in thorough research and competitive analysis, allowing us to position your brand effectively within the market landscape. Our team also designs comprehensive brand guidelines and messaging frameworks that serve as the playbook for consistent, impactful brand communication. The result is a brand strategy that not only sets the direction for your brand's growth but also ensures that every interaction and touchpoint with your audience is purposeful and meaningful. </p>

          </div>
        </div>
        </div>
      </div>
      <div className="homeContactContainer servicesContact">
      <div className="homeContactGrid pageBody">
        <div className="homeContactLeft">
          <h2>Contact Us</h2>
        <ContactForm />
        </div>
      <div className="homeContactRight">
        <h2>Testimonials</h2>
        <Testimonials />
      </div>
    </div>
    </div>
      </div>
      </div>
  );
};

export default Branding;


