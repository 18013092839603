import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import downArrow from "../img/downArrow.png";
import upArrow from "../img/upArrow.png";

import facebook from "../img/fbicon.png";
import instagram from "../img/instaicon.png";
import linkedin from "../img/inicon.png";

const MainMenu = () => {
  const [isActive, setIsActive] = useState(false);
  const submenuRef = useRef(null);

  const toggleSubMenu = () => {
    setIsActive((current) => !current);
  };

  const closeSubMenu = () => {
    setIsActive(false);
  };

  const handleServicesClick = (event) => {
    event.stopPropagation();
    if (isActive) {
      closeSubMenu();
    } else {
      toggleSubMenu();
    }
  };

  const handleDocumentClick = (event) => {
    if (submenuRef.current && !submenuRef.current.contains(event.target)) {
      closeSubMenu();
    }
  };

  // Attach a click event listener to the document to close the submenu when clicking outside.
  React.useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const linkStyle = {
    textDecoration: "none",
    color: "inherit", // Use the same color as other links
  };

  return (
    <div>
      <nav>
        <ul>
          <li>
            <Link to="/" style={linkStyle} className="firstLink">
              Home
            </Link>
          </li>
          <li>
            <span
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "pointer",
              }}
              onClick={handleServicesClick}
            >
              {isActive ? (
                <Link
                  to="services"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Services
                </Link>
              ) : (
                <span style={linkStyle}>
                  Services
                </span>
              )}
              {(!isActive ? downArrow : upArrow) && (
                <img
                  src={!isActive ? downArrow : upArrow}
                  alt={!isActive ? "down arrow" : "up arrow"}
                  className={!isActive ? "downArrow" : "upArrow"}
                  onClick={toggleSubMenu}
                />
              )}
            </span>

            <div ref={submenuRef} className="submenu">
              <ul className={isActive ? "subMenuActive" : "subMenuInactive"}>
                <Link to="websites" onClick={closeSubMenu}>
                  <li>Websites</li>
                </Link>
                <Link to="appdevelopment" onClick={closeSubMenu}>
                  <li>App Development</li>
                </Link>
                <Link to="branding" onClick={closeSubMenu}>
                  <li>Branding</li>
                </Link>
                <Link to="seo" onClick={closeSubMenu}>
                  <li>SEO</li>
                </Link>
                <Link to="copywriting" onClick={closeSubMenu}>
                  <li>Copywriting</li>
                </Link>
                <Link to="emailmarketing" onClick={closeSubMenu}>
                  <li>Email Marketing</li>
                </Link>
              </ul>
            </div>
          </li>
          <li>
            <Link to="techstack" style={linkStyle}>
              Tech Stack
            </Link>
          </li>
          <li>
            <Link to="portfolio" style={linkStyle}>
              Our Work
            </Link>
          </li>
          <li>
            <Link to="contact" style={linkStyle}>
              Contact
            </Link>
          </li>
          <li>
          <a href="https://www.facebook.com/risingstardigitalnz/" target="_blank" rel="noreferrer" className="socialIcon"><img src={facebook} alt="facebook icon" /></a>
          </li>
          <li>
          <a href="https://www.instagram.com/risingstardigitalnz/" target="_blank" rel="noreferrer"className="socialIcon"><img src={instagram} alt="instagram icon" /></a>
          </li>
          <li>
          <a href="https://www.linkedin.com/company/rising-star-digital-nz" target="_blank" rel="noreferrer"className="socialIcon"><img src={linkedin} alt="linkedin icon" /></a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MainMenu;
