import React from "react";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";

import ContactForm from "./contactForm";
import Testimonials from "./testimonials";


const Portfolio = () => {
  return (
    <div className="portfolioContainer">
    <div className="portfolio">
      <Outlet />
    </div>
    <div className="downloadBlock">
      <div className="pageBody">
      <div className="downloadGrid">
        <div className="downloadLeft">
        </div>
        <div className="downloadRight">
          <h2>Free Website Audit</h2>
          <p>If you have a website already, are you curious about how well it's performing and where it could be improved?</p> 
          
          <p>Let us take a deep dive into your digital footprint, analyzing every element from design and functionality to SEO and user experience. Our expert team will provide you with valuable insights and recommendations to enhance your website's effectiveness.</p>
          <p>Harness the power of data-driven decisions and make your online presence the best it can be. Get started with a free website audit today and pave the way for a more successful digital future.</p>

          <Link to="/contact"><p className="button">Claim Free Audit</p></Link>
        </div>
      </div>
      </div>
    </div>

    <div className="homeContactContainer">
      <div className="homeContactGrid pageBody">
        <div className="homeContactLeft">
          <h2>Contact Us</h2>
        <ContactForm />
        </div>
      <div className="homeContactRight">
        <h2>Testimonials</h2>
        <Testimonials />
      </div>
    </div>
    </div>
    </div>
  );
};

export default Portfolio;
