import React from "react";
import { Link } from "react-router-dom";
//images
import seoIcon from "../img/plugIcon.png";
import chessIcon from "../img/rookIcon.png";
import panda from "../img/red-panda.jpg"

//components
import ContactForm from "./contactForm";
import Testimonials from "./testimonials";

const SEO = () => {
 
  return (
    <div className="servicesContainer seoContainer">
      <div className="mobileImage">
          <img src={panda} alt="red panda" />
        </div>
    <div className="servicesInner">
      <div className="servicesPageFeature pageBody">
      <div className="servicesPageLeft">
      <h1>Search Engine Optimisation</h1>
      <p>In the digital age, search engine optimization (SEO) stands as the beacon that guides your online presence to new horizons. We are skilled in the art and science of SEO, ensuring your brand's visibility and relevance are meticulously sculpted to shine on the digital stage.</p>

      <p>We understand that SEO is not just about keywords; it's about enhancing your website's credibility, authority, and visibility in the ever-competitive digital landscape. We take a holistic approach, diving deep into your brand's identity, target audience, and market trends. We carefully navigate the ever-evolving algorithms of major search engines, ensuring your content ranks higher, reaches the right audience, and resonates with them effectively. Whether you're aiming to capture the attention of local customers, expand your global reach, or secure top-ranking positions, we're here to optimize your online presence and make your brand's voice heard. </p>

      <Link to="/contact"><p className="button">Contact Us</p></Link>

      </div>
      <div className="servicesPageRight">

      </div>
      </div>
      
      <div className="servicesIndividualBlocks">
        <div className="pageBody">
        <div className="servicesIndividualRow">
          <div className="servicesIndividualLeftBlock">
            <img src={chessIcon} alt="rook icon" className="servicesIcon" />
            <h2>Keyword Research and Strategy</h2>
            <p>Effective keyword research and strategy are the pillars upon which successful SEO campaigns are built. We recognise that understanding your target audience and their search behavior is fundamental to optimising your online presence.</p>

            <p>Our keyword research process begins with a deep dive into your business objectives and market dynamics. We work closely with you to identify the core keywords and key phrases that are not only relevant to your industry but also align with your brand's unique selling points. With a focus on long-tail keywords and semantic search, we ensure that the selected keywords encapsulate the nuances of your products or services. We delve into the minds of your potential customers, considering their search intent and the questions they seek answers to.</p>

            <p>The result is a comprehensive keyword strategy that goes beyond mere optimization - it forms the foundation of your content, on-page SEO, and off-page SEO efforts. We meticulously map keywords to specific pages and content types, ensuring that each piece of your digital footprint is strategically aligned with your brand's objectives. With a focus on both high-competition and niche keywords, our strategy is designed to position your website in the search engine rankings and increase your online visibility. We also keep a watchful eye on emerging trends and industry-specific jargon, ensuring that your keyword strategy remains agile and adaptable.</p>
          </div>
          <div className="servicesIndividualRightBlock">
          <img src={seoIcon} alt="SEO icon" className="servicesIcon"/>
          <h2>Site Credibility and Authority</h2>
            <p>In the intricate world of SEO, building site credibility and authority is the path to enhancing your online visibility. We understand that the digital landscape is teeming with information, and ensuring your website stands out as a trustworthy and authoritative source is paramount.</p>

            <p>Credibility begins with a comprehensive site audit, where we scrutinise every aspect of your website, from its design and user experience to its content quality and performance. We focus on creating a seamless and engaging user experience, as search engines increasingly value websites that offer value and reliability to their visitors.</p>

            <p>Site authority, on the other hand, is built through a meticulous content and link-building strategy. We work to position your website as an industry expert by producing high-quality, informative content that not only resonates with your audience but also showcases your expertise. Our link-building efforts target reputable sources, enhancing your website's authority and relevance in the eyes of search engines.</p>

            <p>The result is a website that exudes credibility, authority, and trust, increasing your online visibility and ensuring your brand is prominently featured in search engine results. With our commitment to maintaining and enhancing your site's credibility and authority, we illuminate the path to digital prominence and ensure that your brand not only participates in online conversations but leads them. </p>
          </div>
        </div>
        </div>
      </div>
      <div className="homeContactContainer servicesContact">
      <div className="homeContactGrid pageBody">
        <div className="homeContactLeft">
          <h2>Contact Us</h2>
        <ContactForm />
        </div>
      <div className="homeContactRight">
        <h2>Testimonials</h2>
        <Testimonials />
      </div>
    </div>
    </div>
      </div>
      </div>
  );
};

export default SEO;


