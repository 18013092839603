import React from "react";
import PortfolioIndex from "./portfolioindex";
import Testimonials from "./testimonials";
import { Link } from "react-router-dom";
import ContactForm from "./contactForm";

//images
import websiteIcon from "../img/worldIcon.png";
import appIcon from "../img/mobileIcon.png";
import seoIcon from "../img/plugIcon.png";
import metalmagic from "../img/metalmagiclogo.png";
import bird from "../img/bird-edit.jpg";
import mic from "../img/micIcon.png";
import emailIcon from "../img/emailMarketingIcon.png";
import pageIcon from "../img/pageIcon.png";


const HomePage = () => {
  return (
    <>
    <div className="mobileImage">
          <img src={bird} alt="bird" />
        </div>
   <div className="homePage">
    <div className="homeContainer pageBody">
      <div className="homeIntro">
        <div className="homeBlurb">
          <h1>Welcome to Rising Star Digital.</h1>
          <p>
          We bring innovation, creativity, and technical expertise together to create professional magic. As development specialists, we turn your digital dreams into reality. Whether it's a website, mobile app, or online optimisation, we've got you covered. Join us to shape the future together.</p> 
          <p><strong>Your vision, our code - let's make it extraordinary.</strong>
          </p>

          <Link to="/contact"><p className="button">Contact Us</p></Link>
        </div>
        
      </div>
      </div>
</div>
<div className="homeServicesContainer">
      <div className="homeServices pageBody">
      <h2>Services</h2>
      <div className="servicesGrid">
        <div className="servicesGridRow">
      <div className="servicesLeft">
        <img src={websiteIcon} alt="World Icon" className="homeIcon"/>
        <h3>Websites</h3>
        <p>We specialise in crafting websites that not only captivate, but also engage. Our team of dedicated experts is passionate about building stunning, user-friendly websites that are not only visually appealing, but that deliver exceptional user experiences. We provide everything from simple builds through to fully custom solutions.</p>
        <Link to="/websites"><p className="button">More Info</p></Link>
        </div>

      <div className="servicesCentre">
        <img src={appIcon} alt="computer network icon" className="homeIcon"/>
        <h3>App Development</h3>
        <p>We're dedicated to creating unique, high-quality applications that excel in functionality, providing users with seamless and memorable experiences. Whether it's an intuitive user interface, cutting-edge features, or robust performance, we go the extra mile to ensure your app stands out in a competitive market.</p>
        <Link to="/appdevelopment"><p className="button">More Info</p></Link>
      </div>

      <div className="servicesRight">
        <img src={seoIcon} alt="computer plug icon" className="homeIcon"/>
        <h3>SEO</h3>
        <p>Elevate your online presence with the power of our SEO expertise. We optimize your digital footprint to ensure your business shines on search engines. Our team's proficiency lies in the art and science of SEO, making sense of the algorithms, and helping your website climb the ranks and attract more organic traffic. </p>
        <Link to="/seo"><p className="button">More Info</p></Link>
      </div>
      </div>
      <div className="servicesGridRow">
      <div className="servicesLeft">
        <img src={mic} alt="World Icon" className="homeIcon"/>
        <h3>Branding and Strategy</h3>
        <p>Breathe life into your brand's identity. Whether you're initiating a brand from the ground up or reimagining an existing one, we'll shape your essence, distinguish you from the crowd, and develop a strategy that forms a deep connection with your target audience.</p>
        <Link to="/branding"><p className="button">More Info</p></Link>
        </div>

      <div className="servicesCentre">
        <img src={pageIcon} alt="page icon" className="homeIcon"/>
        <h3>Copywriting</h3>
        <p>Craft compelling narratives and persuasive content that captivate and engage your audience. Whether it's website content, marketing collateral, or social media messaging, we're passionate about creating content that not only informs but also inspires action.</p>
        <Link to="/copywriting"><p className="button">More Info</p></Link>
      </div>

      <div className="servicesRight">
      <img src={emailIcon} alt="Email Marketing Icon" className="homeIcon"/>
        <h3>Email Marketing</h3>
        <p>Email marketing is about more than just words; it's building meaningful connections with your audience that engage, inform, and convert. Whether you're launching a product, nurturing leads, or rekindling customer relationships, we'll deliver the right message at the right time.</p>
        <Link to="/emailmarketing"><p className="button">More Info</p></Link>
      </div>
      </div>
      </div>

      </div>
      </div>


    <div className="caseStudy">
      <div className="pageBody">
      <div className="caseStudyGrid">
        <div className="caseLeft">
        <img src={metalmagic} alt="Metal Magic Logo" className="metalMagicLogo" />
        </div>
        <div className="caseRight">
          <h2>Case Study</h2>
          <h3>Metal Magic Piercing</h3>
          <h4>Website Design and Build</h4>
            <p>This project involved building a website from scratch using React with a limited palette of black and gold as the primary colours. The development process included creating a visually stunning and user-friendly website that reflected the brand's personality and aesthetic.</p>

            <p>In addition to design and development, the project also involved deployment, use of security certificates, custom domain redirection, and integration of external systems.</p>

            <p>Overall, this project required technical expertise in web development, as well as experience in deploying and integrating external systems, to create a successful website that accurately reflected the brand's values and achieved its goals.</p>
            <a href="https://metalmagicpiercings.com/" target="_blank" rel="noreferrer"><p className="button">View Live Site</p></a>
        
        </div>
        </div>
      </div>
    </div>
  <div className="ourWork">
    <div className="ourWorkInner">
        <PortfolioIndex />
        </div>
      </div>

    <div className="downloadBlock">
      <div className="pageBody">
      <div className="downloadGrid">
        <div className="downloadLeft">
        </div>
        <div className="downloadRight">
          <h2>Free Website Audit</h2>
          <p>If you have a website already, are you curious about how well it's performing and where it could be improved?</p> 
          
          <p>Let us take a deep dive into your digital footprint, analyzing every element from design and functionality to SEO and user experience. Our expert team will provide you with valuable insights and recommendations to enhance your website's effectiveness.</p>
          <p>Harness the power of data-driven decisions and make your online presence the best it can be. Get started with a free website audit today and pave the way for a more successful digital future.</p>

          <Link to="/contact"><p className="button">Claim Free Audit</p></Link>
        </div>
      </div>
      </div>
    </div>

    <div className="homeContactContainer">
      <div className="homeContactGrid pageBody">
        <div className="homeContactLeft">
          <h2>Contact Us</h2>
        <ContactForm />
        </div>
      <div className="homeContactRight">
        <h2>Testimonials</h2>
        <Testimonials />
      </div>
    </div>
    </div>
    </>
  );
};

export default HomePage;
