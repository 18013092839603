import React from "react";
import { Link } from "react-router-dom";

//images
import webDesignIcon from "../img/webDesignIcon.png";
import worldIcon from "../img/worldIcon.png";
import wolf from "../img/wolf.jpg";

//components
import ContactForm from "./contactForm";
import Testimonials from "./testimonials";

const Copywriting = () => {
 
  return (
    <div className="servicesContainer copywritingContainer">
      <div className="mobileImage">
          <img src={wolf} alt="wolf" />
        </div>
    <div className="servicesInner">
      <div className="servicesPageFeature pageBody">
      <div className="servicesPageLeft">
      <h1>Copywriting</h1>
      <p>In the digital age, the written word is more than mere text; it's the voice of your brand, the connector to your audience, and the essence of your online presence. We are seasoned experts in the craft of copywriting, where words are woven into narratives that captivate, inform, and inspire action.</p>

      <p>We understand that in the realm of online presence, every sentence and phrase is an opportunity to connect with your audience. We go beyond the confines of persuasive language and delve into the core of your brand identity, your target audience, and your digital objectives. Whether you're seeking engaging website content, impactful marketing collateral, or compelling social media messaging, we're here to craft content that not only informs but also leaves a lasting impression. With our copywriting expertise, your message resonates, your story compels, and your online presence takes center stage in the digital arena.</p>

      <Link to="/contact"><p className="button">Contact Us</p></Link>

      </div>
      <div className="servicesPageRight">

      </div>
      </div>
      
      <div className="servicesIndividualBlocks">
        <div className="pageBody">
        <div className="servicesIndividualRow">
          <div className="servicesIndividualLeftBlock">
            <img src={webDesignIcon} alt="web design icon" className="servicesIcon" />
            <h2>Blog Articles for SEO</h2>
            <p>Blogging is more than just putting words on a page; it's the art of engaging, informing, and building connections with your target audience. We recognize the significance of blog posts in digital strategy, and our approach to blog post writing is a blend of creativity, research, and strategic thinking.</p>

            <p>Our blog post writing process begins with a deep understanding of your product, your industry, and the interests of your target audience. We collaborate with you to define the goals of each blog post, whether it's to inform, entertain, inspire, or drive action. We then meticulously research the chosen topic, delving into industry trends, consumer pain points, and keyword opportunities to ensure your blog posts not only capture your audience's attention but also address their specific needs.</p>

            <p>With a focus on engaging introductions, clear structuring, and compelling narratives, our writers craft blog posts that not only inform but also hold the reader's attention. Each post is optimized for SEO, ensuring that it's discoverable in search engine results. We also incorporate visual elements, such as images and infographics, to enhance the reader's experience and encourage sharing.</p>

            <p>The result is a blog post that resonates with your audience, positions your brand as an industry authority, and drives traffic to your website. With our dedication to creating blog content that informs, entertains, and engages, we help you make a memorable impact in the digital landscape.</p>

          </div>
          <div className="servicesIndividualRightBlock">
          <img src={worldIcon} alt="world icon" className="servicesIcon" />
          <h2>Crafting an Online Presence</h2>
            <p>Creating a comprehensive online presence requires more than just a website; it demands a strategic and engaging approach across various digital touchpoints. We deliver content solutions that cover web pages, email campaigns, product descriptions, and landing pages, among others.</p>

            <p>For website content, our focus is on creating engaging, informative, and user-friendly experiences. From your homepage to product or service pages, our content is meticulously crafted to resonate with your audience, communicate your brand's value, and guide visitors to take desired actions.</p>

            <p>In the realm of email campaigns, we design content that captures your audience's attention and delivers value. Whether it's newsletters or promotional emails, our content engages, encourages open rates, and compels recipients to act.</p>

            <p>Product descriptions in e-commerce are the bridge between your products and your customers. Our product descriptions are more than just details; they tell a story, spark desire, and answer questions, making shopping an engaging experience.</p>

            <p>Landing pages are dedicated to conversions, and our landing page content is designed to convince and convert visitors. We deliver a compelling message that encourages sign-ups, downloads, purchases, or other actions.</p>

            <p>With our comprehensive approach to writing for various online channels, we help you establish a compelling digital footprint and make a lasting impact in the online arena.</p>

          </div>
        </div>
        </div>
      </div>
      <div className="homeContactContainer servicesContact">
      <div className="homeContactGrid pageBody">
        <div className="homeContactLeft">
          <h2>Contact Us</h2>
        <ContactForm />
        </div>
      <div className="homeContactRight">
        <h2>Testimonials</h2>
        <Testimonials />
      </div>
    </div>
    </div>
      </div>
      </div>
  );
};

export default Copywriting;


