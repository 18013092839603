import React from "react";
import { useForm, ValidationError } from "@formspree/react";

import facebook from "../img/fbicon.png";
import instagram from "../img/instaicon.png";
import linkedin from "../img/inicon.png";

const ContactForm = () => {
  const [state, handleSubmit] = useForm("xdoryzez");
  if (state.succeeded) {
    return <p>Thanks for your message. I will be in touch with you soon.</p>;
  }
  return (
    <div className="contactForm">
      <div className="homeContactLeft">
        <h3>Find us on Social Media</h3>

          <a href="https://www.facebook.com/risingstardigitalnz/" target="_blank" rel="noreferrer" className="socialIcon"><img src={facebook} alt="facebook icon" /></a>

          <a href="https://www.instagram.com/risingstardigitalnz/" target="_blank" rel="noreferrer"className="socialIcon"><img src={instagram} alt="instagram icon" /></a>

          <a href="https://www.linkedin.com/company/rising-star-digital-nz" target="_blank" rel="noreferrer"className="socialIcon"><img src={linkedin} alt="linkedin icon" /></a>

        <hr />

        <h3>
          Phone: <a href="tel:0274054979">027 405 4979</a>
        </h3>
        <hr />
        <h3>Email us using the form below. </h3>

        <form onSubmit={handleSubmit}>
          <input
            id="name"
            type="text"
            name="name"
            placeholder="Name *"
            required
          />

          <input
            id="phone"
            type="text"
            name="phone"
            placeholder="Phone Number"
          />

          <input
            id="email"
            type="email"
            name="email"
            placeholder="Email *"
            required
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
          <textarea
            id="message"
            name="message"
            placeholder="How can we help you? *"
            required
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />

          <p className="disclaimer">
            By submitting this form, you agree to our{" "}
            <a href="/privacy">privacy policy.</a>
          </p>

          <button type="submit" disabled={state.submitting}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
