import React from "react";
import logo from "../img/logorev500.png";
import Navigation from "./navigation";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header>
      <div className="headerContainer">
        <div className="contentContainer">
          <div className="logo" id="pageTop">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img src={logo} alt="Rising Star Digital Logo" className="logo" />
            </Link>
          </div>
          <Navigation />
        </div>
      </div>
    </header>
  );
};

export default Header;
