import React from "react";
import { Link } from "react-router-dom";

//images
import appIcon from "../img/mobileIcon.png";
import plugIcon from "../img/plugIcon.png";
import mic from "../img/micIcon.png";
import emailIcon from "../img/emailMarketingIcon.png";
import cameraIcon from "../img/cameraIcon.png";
import pageIcon from "../img/pageIcon.png";
import codeIcon from "../img/codeIcon.png";
import wordpressIcon from "../img/wordpressIcon.png";
import webDesignIcon from "../img/webDesignIcon.png";
import stag from "../img/stag.jpg";

//components
import ContactForm from "./contactForm";
import Testimonials from "./testimonials";

const Services = () => {

  return (
   <div className="servicesContainer">
            <div className="mobileImage">
          <img src={stag} alt="stag banner" />
        </div>
    <div className="servicesInner">
      <div className="servicesPageFeature pageBody">
      <div className="servicesPageLeft">

      <h1>Services</h1>
      <p>We provide a diverse range of tailored digital solutions to elevate your online presence. These include website design and development, app creation, branding, and SEO optimization.</p> 
      <p>Our experienced team is ready to work closely with you to transform your ideas into digital realities. Whether you're looking to enhance your customer's user experience, create a mobile app that resonates with your audience, establish a unique brand identity, or boost your online visibility, we have the expertise to meet your specific needs.</p> 
      <p><strong>Explore our individual services in detail and see how we can be your trusted partner on the path to digital success.</strong></p>

      <p className="button">Contact Us</p>

      </div>
      <div className="servicesPageRight">

      </div>
      </div>
      
      <div className="servicesPageBlocks">
        <div className="pageBody">
        <div className="servicesPageRow">
          <div className="servicesPageLeftBlock">
            <img src={webDesignIcon} alt="laptop icon" className="servicesIcon" />
            <h2>Website Design</h2>
            <p>Website design is the digital face of your brand. Our team craft visually captivating and user-friendly websites that resonate with your audience. Whether it's a fresh redesign or a brand-new site, we're committed to making your online presence shine. Let's transform your vision into a stunning digital reality.</p>
            <Link to="/websites"><p className="button">More Info</p></Link>
          </div>
          <div className="servicesPageCentreBlock">
          <img src={wordpressIcon} alt="WordPress icon" className="servicesIcon"/>
          <h2>WordPress Builds</h2>
            <p>Unlock the potential of WordPress with our website build services. We specialize in creating dynamic, feature-rich, cost-effective websites that are easy to manage and navigate. Whether it's a blog, business site, or e-commerce platform, we harness the power of WordPress to craft a web presence that suits your unique needs.</p>
            <Link to="/websites"><p className="button">More Info</p></Link>
          </div>
          <div className="servicesPageRightBlock">
          <img src={codeIcon} alt="coding icon" className="servicesIcon"/>
          <h2>Custom Website Builds</h2>
            <p>Need something more customised? Elevate your digital presence with our React website builds. We build responsive, high-performance websites that leverage the capabilities of React.js. From interactive user interfaces to lightning-fast loading times, our team excels in harnessing the full potential of this cutting-edge technology. </p>
            <Link to="/websites"><p className="button">More Info</p></Link>
          </div>
        </div>
        <div className="servicesPageRow">
          <div className="servicesPageLeftBlock">
            <img src={appIcon} alt="app development icon" className="servicesIcon"/>
            <h2>App Development</h2>
            <p>We're dedicated to creating mobile applications that go beyond functionality - they provide memorable user experiences. Whether you have a groundbreaking idea or want to improve an existing app, our team is ready to turn your vision into reality. We're here to design, develop, and deliver apps that stand out.</p>
            <Link to="/appdevelopment"><p className="button">More Info</p></Link>
          </div>
          <div className="servicesPageCentreBlock">
          <img src={mic} alt="microphone icon" className="servicesIcon"/>
          <h2>Branding and Strategy</h2>
            <p>Breathe life into your brand's identity. Our seasoned team of strategists craft compelling brand narratives. Whether you're initiating a brand from the ground up or reimagining an existing one, we'll shape your essence, distinguish you from the crowd, and develop a strategy that forms a deep connection with your target audience.</p>
            <Link to="/branding"><p className="button">More Info</p></Link>
          </div>
          <div className="servicesPageRightBlock">
          <img src={plugIcon} alt="computer plug icon" className="servicesIcon"/>
          <h2>Search Engine Optimisation</h2>
            <p>Enhance your website's visibility and drive organic traffic. With a keen eye on the ever-changing algorithms and a data-driven approach, we work to ensure your content ranks higher and resonates better with your target audience. Let's navigate the SEO landscape and position your brand at the forefront of search engine results.</p>
            <Link to="/seo"><p className="button">More Info</p></Link>
          </div>
        </div>
        <div className="servicesPageRow">
          <div className="servicesPageLeftBlock">
            <img src={pageIcon} alt="page icon" className="servicesIcon"/>
            <h2>Copywriting</h2>
            <p>Craft compelling narratives and persuasive content that captivate and engage your audience. Whether it's website content, marketing collateral, or social media messaging, we're passionate about creating content that not only informs but also inspires action.</p>
            <Link to="/copywriting"><p className="button">More Info</p></Link>
          </div>
          <div className="servicesPageCentreBlock">
          <img src={emailIcon} alt="email marketing icon" className="servicesIcon"/>
          <h2>Email Marketing</h2>
            <p>Email marketing is about more than just words; it's building meaningful connections with your audience that engage, inform, and convert. Whether you're launching a product, nurturing leads, or rekindling customer relationships, we'll deliver the right message at the right time.</p>
            <Link to="/emailmarketing"><p className="button">More Info</p></Link>
          </div>
          <div className="servicesPageRightBlock">
          </div>
          </div>
        </div>
      </div>
      <div className="homeContactContainer servicesContact">
      <div className="homeContactGrid pageBody">
        <div className="homeContactLeft">
          <h2>Contact Us</h2>
        <ContactForm />
        </div>
      <div className="homeContactRight">
        <h2>Testimonials</h2>
        <Testimonials />
      </div>
    </div>
    </div>
      </div>
      </div>
  );
};

export default Services;
