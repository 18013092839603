const portfolioData = {
  "unodex-crypto-trading-app": {
    name: "Unodex",
    subtitle: "Web App",
    projectBrief:
      "Build the front-end using for a crypto trading application using React",
    description: `<p><strong>This was a collaborative project where we built the front-end in React for a complex crypto trading web application.</strong></p>
  
    <p>We were provided with mockups for the layout of the application, as well as access to a custom API which was built to run the back-end. We collaborated with the back-end development team to create the interface for the entire web app within a tight time-frame. </p>

    <p>We worked closely with the other developers to bring their ideas to life, providing a usable interface to complete their concept.</p>

    <p>This involved a number of technical elements:
    <ul>
    <li>Web 3 based wallet connection using Metamask and other associated wallet providers</li>
    <li>Secure access for users wanting to trade using the platform</li>
    <li>Restricted viewing based on whether the user has connected securely</li>
    <li>Fully responsive build using React</li>
    <li>Dark mode including a user-friendly toggle</li>
    <li>Collapsable navigation</li>
    <li>API integration to bring in live chart data</li>
    <li>State management to allow data to be used across the application</li>
    <li>DRY programming styles to ensure efficiency with API calls and performance</li>
    <li>Wide range of server calls based on the choices of the user</li>
    <li>Historical trade data specific to the user, including filtering for pagination</li>
    <li>Placeholder elements for features to be added at a later date</li>
    <li>Git version control management in collaboration with the other development team</li>
    </ul></p>

    <p className="button"><a href="https://unodex.app/" target="_blank" rel="noreferrer">View Live Web App</a></p>
`,
    technicalSpec:
      "<p>Built using React, Node, and custom javascript. Optimised for page speed, accessibility, and mobile responsiveness.</p>",
    featureImage: `/unodex/featured.jpeg`,
    imageGallery: [
      {
        imageSrc: `/unodex/featured.jpeg`,
        imageAlt: `Unodex web app mockup.`,
      },
      {
        imageSrc: `/unodex/landing-web.jpeg`,
        imageAlt: `Unodex landing page mockup.`,
      },
      {
        imageSrc: `/unodex/trades-wallets-web.jpeg`,
        imageAlt: `Unodex trades page and wallets page mockup.`,
      },
    ],
  },
  "metal-magic-website": {
    name: "Metal Magic",
    subtitle: "Website",
    projectBrief:
      "Design and build a React website for Metal Magic Piercing",
    description: `<p><strong>This website was designed and built for a local piercing studio in Auckland using React. </strong></p>
  
    <p>This project involved building a website from scratch using React with a limited palette of black and gold as the primary colours. The development process included creating a visually stunning and user-friendly website that reflected the brand's personality and aesthetic.</p>

    <p>In addition to design and development, the project also involved deployment, use of security certificates, custom domain redirection, and integration of external systems.</p>

    <p>Overall, this project required technical expertise in web development, as well as experience in deploying and integrating external systems, to create a successful website that accurately reflected the brand's values and achieved its goals.</p>

    <p className="button"><a href="https://metalmagicpiercings.com/" target="_blank" rel="noreferrer">View Live Site</a></p>
`,
    technicalSpec:
      "<p>Built using React, Node, and custom javascript. Optimised for page speed, accessibility, and mobile responsiveness.</p>",
    featureImage: `/metal-magic/home-page-on-laptop.jpeg`,
    imageGallery: [
      {
        imageSrc: `/metal-magic/home-page-on-laptop.jpeg`,
        imageAlt: `Metal Magic website mock up.`,
      },
      {
        imageSrc: `/metal-magic/tablet-view.jpeg`,
        imageAlt: `Metal Magic website mock up.`,
      },
    ],
  },
  "rural-assist-website": {
    name: "Rural Assist",
    subtitle: "Website",
    projectBrief:
      "Design and build a WordPress website for Rural Assist",
    description: `<p><strong>This website was designed and built for an agricultural health and safety business based in Palmerston North.</strong></p>
  
    <p>After designing the branding for Rural Assist, we also created their website. This needed to be a brochure website that highlighted their services while also bringing focus to their points of difference from their competitors.</p>

    <p>In addition to designing the visual elements, we also wrote the majority of the content for the site in consultation with the client. This meant we needed to get to understand their business enough to be able to speak to their customer base effectively and accurately.</p>

    <p>We prioritised performance optimisation on both mobile and desktop, while maintaining interesting visual elements in each core site section. We also made a point of ensuring the site is accessible for all users, and set up for high quality usability.</p>

    <p className="button"><a href="https://ruralassist.co.nz/" target="_blank" rel="noreferrer">View Live Site</a></p>
`,
    technicalSpec:
      "<p>Built using WordPress. Optimised for page speed, accessibility, and mobile responsiveness. It utilised CDN content serving, image compression optimisation, and dynamic loading to aid in its speed.</p>",
    featureImage: `/rural-assist/feature.jpeg`,
    imageGallery: [
      {
        imageSrc: `/rural-assist/3-tablets.jpeg`,
        imageAlt: `Rural Assist website mock up.`,
      },
      {
        imageSrc: `/rural-assist/home-tablet.jpeg`,
        imageAlt: `Rural Assist website mock up.`,
      },
      {
        imageSrc: `/rural-assist/about-tablet-scroll.jpeg`,
        imageAlt: `Rural Assist website mock up.`,
      },
      {
        imageSrc: `/rural-assist/laptop.jpeg`,
        imageAlt: `Rural Assist website mock up.`,
      },
    ],
  },
  "liberate-it-website": {
    name: "Liberate I.T.",
    subtitle: "Website",
    projectBrief:
      "Design and build new websites for New Zealand and Australia based on the newly created branding, and brand positioning.",
    description: `<p><strong>This project was part of an overall overhaul that was completed in 2019 for Liberate I.T. and included many pieces of collateral as well as the website. </strong></p>
  
    <p>This included not just a design and build of the framework, but also completely reworking the content and structure. It had to be fully mobile responsive, and had to communicate to a very specific audience. This involved a balance of technical terminology, and general explanation.</p>

    <p>Because Liberate I.T. operated in both New Zealand and Australia, and they had specific offerings for each place, they wanted to have two separate sites using two location-specific domains. This meant creating a balance between content across both, and setting up a technical environment that would still be easy to manage for both interfaces.</p>
`,
    technicalSpec:
      "<p>Built using WordPress. Optimised for page speed, accessibility, and mobile responsiveness.</p><p>This website was built using multisite, and hosted on an excellent WordPress-specific hosting platform. It was created and built with performance and easy maintenance in mind, with a particular focus toward mobile usability. It utilised CDN content serving, image compression optimisation, and dynamic loading to aid in its speed.</p><p>Liberate I.T. have since made the switch to a single site for both locations, and made some changes to their overall design.</p>",
    featureImage: `/liberate/home-tablet-1.jpeg`,
    imageGallery: [
      {
        imageSrc: `/liberate/home-tablet-1.jpeg`,
        imageAlt: `Liberate I.T. website mock up.`,
      },
      {
        imageSrc: `/liberate/homeScroll.jpeg`,
        imageAlt: `Liberate I.T. website mock up.`,
      },
      {
        imageSrc: `/liberate/tabletTeamNew.jpeg`,
        imageAlt: `Liberate I.T. website mock up.`,
      },
    ],
  },
  "liberate-it-edm": {
    name: "Liberate I.T.",
    subtitle: "Email Marketing",
    projectBrief:
      "Create monthly emails for communicating with the existing customer base.",
    description: `<p>As part of working with Liberate I.T. a monthly Info Hub messaging EDM was designed and built for their existing customer base. This was set up as a means of communicating what events were coming up, free webinars customers could join in on, promotional offers, and helping them get to know the wider staff.</p>
  
    <p>These EDMs were created by hard-coding custom HTML from scratch, using a series of templates that were also built for them. They were then loaded  into NetSuite (their internal ERP system including CRM functionality), and sent it out to their customer base from there.</p>
`,
    technicalSpec: "<p>Built using HTML and CSS, distributed via NetSuite.</p>",
    featureImage: `/liberate/edm1.jpeg`,
    imageGallery: [
      {
        imageSrc: `/liberate/edm1.jpeg`,
        imageAlt: `Liberate I.T. Info Hub EDM.`,
      },
      {
        imageSrc: `/liberate/edmLong-1.jpeg`,
        imageAlt: `Liberate I.T. Info Hub EDM.`,
      },
    ],
  },
  inugo: {
    name: "Inugo",
    subtitle: "Website",
    projectBrief:
      "Rollout and build a WordPress website based on a supplied partial design.",
    description: `<p><strong>Inugo is a parking app that is trying to change the way we park the world over.</strong></p>
  
    <p>While managing the website through 2017 and 2018, the app was active both within New Zealand and also America. Because of this, the client needed the site to be set up to serve messaging specific to different audiences and locations, without compromising performance.</p>
`,
    technicalSpec:
      "<p>Built using WordPress. Utilises WordPress, internal CRM, user geolocation-specific content. Optimised for page speed, accessibility, and mobile responsiveness.</p><p>This website was built using WordPress, and integrated with the client's internal CRM, which required custom coding to achieve.</p><p>This meant that optimisation was a priority, as the custom aspects of the build had the potential to slow down site performance.</p><p>The content within the website was configured to serve whatever message was the most appropriate for the user based on their geolocation. Both within-page content, and also full page url-based redirection was included to accommodate this.</p> ",
    featureImage: `/inugo/Inugo-featuredWEB.jpeg`,
    imageGallery: [
      {
        imageSrc: `/inugo/Inugo-featuredWEB.jpeg`,
        imageAlt: `Inugo website mock up.`,
      },
      {
        imageSrc: `/inugo/Inugo-aboutWEB.jpeg`,
        imageAlt: `Inugo website mock up.`,
      },
      {
        imageSrc: `/inugo/Inugo-fcbWEB.jpeg`,
        imageAlt: `Inugo website mock up.`,
      },
      {
        imageSrc: `/inugo/Inugo-home2WEB.jpeg`,
        imageAlt: `Inugo website mock up.`,
      },
    ],
  },
  "this-is-electric": {
    name: "This Is Electric",
    subtitle: "Website",
    projectBrief: "Design and build a website based on a newly created brand.",
    description: `<p>This Is Electric was an Auckland-based electrician business. Their existing website wasn't performing well for them, and they needed a full overhaul to meet their needs within their industry. Their new site required a fresh design, build, and all-new content. This meant getting to know their points of difference, and their services well enough to create useful and meaningful copy to accompany the design.</p>
`,
    technicalSpec:
      "<p>Built using WordPress. Optimised for page speed, accessibility, mobile responsiveness, easy updates.</p>",
    featureImage: `/this-is-electric/home.jpeg`,
    imageGallery: [
      {
        imageSrc: `/this-is-electric/home.jpeg`,
        imageAlt: `This Is Electric website mock up.`,
      },
      {
        imageSrc: `/this-is-electric/home1.jpeg`,
        imageAlt: `This Is Electric website mock up.`,
      },
      {
        imageSrc: `/this-is-electric/blog.jpeg`,
        imageAlt: `This Is Electric website mock up.`,
      },
      {
        imageSrc: `/this-is-electric/services.jpeg`,
        imageAlt: `This Is Electric website mock up.`,
      },
    ],
  },

  "miss-marie-web": {
    name: "Miss Marie",
    subtitle: "Website",
    projectBrief:
      "Design and build an e-commerce website, including all brand elements, photography, and payment system integration.",
    description: `<p>This project involved full roll out across a range of collateral, both online and in print. Miss Marie was an online store that sold pinup, gothic, steampunk and alternative clothing. </p>
  
    <p>The website needed to be easy to use, fit the theme of the clothing styles, and be fast to load. There also needed to be the capacity for custom product filtering, as well as detailed product searches. I set up a full content management system, making content updates easy, as well as adding or removing of products. It also made it simple to share content across multiple platforms. It was fully mobile responsive.</p>

    <p>The photography was completed in conjunction with Jessie Rolton Photography. This included some of the products shots, as well as a model shoot showcasing the most popular stock.</p>
`,
    technicalSpec:
      "<p>Built using WordPress and WooCommerce. Optimised for page speed and accessibility.</p>",
    featureImage: `/miss-marie/website-iPad-homepageWEB.jpeg`,
    imageGallery: [
      {
        imageSrc: `/miss-marie/website-blog-ipadWEB.jpeg`,
        imageAlt: `Miss Marie website mock up.`,
      },
      {
        imageSrc: `/miss-marie/website-iPad-homepageWEB.jpeg`,
        imageAlt: `Miss Marie website mock up.`,
      },
      {
        imageSrc: `/miss-marie/website-product-pageWEB.jpeg`,
        imageAlt: `Miss Marie website mock up.`,
      },
    ],
  },
  "dns-forest-products": {
    name: "DNS",
    subtitle: "Website",
    projectBrief:
      "Build a website and rollout the design based on a homepage design and provided assets.",
    description: `<p><strong>Working in conjunction with <a href="http://www.chriswardcreative.com/" target="_blank" rel="noreferrer">Chris Ward Creative</a>, a new website for DNS Forest Products was created. The key aims included ease of use, ease of updates, clarity of information, and being fully mobile responsive. It also needed to have a working translation option for three languages.</strong></p>

    <p>After receiving the design elements and discussing options around building, the client was provided with a full CMS based site that they could update themselves, as well as documentation on how to do so.</p>

    <p>A clean, well-functioning website build that was accurate to the creative was the final product.</p>
`,
    technicalSpec:
      "<p>Built using WordPress. Optimised for page speed, accessibility, mobile responsiveness, and easy updates.</p>",
    featureImage: `/dns-forest-products/DNS-ipadWEB.jpeg`,
    imageGallery: [
      {
        imageSrc: `/dns-forest-products/DNS-ipadWEB.jpeg`,
        imageAlt: `DNS website mock up on ipad.`,
      },
      {
        imageSrc: `/dns-forest-products/DNS-laptopWEB.jpeg`,
        imageAlt: `DNS website mock up on laptop.`,
      },
    ],
  },
  "mood-hair": {
    name: "Mood Hair",
    subtitle: "Website",
    projectBrief: "Design and build a single page website to promote services.",
    description: `<p><strong>Mood Hair Studio is based in Drury, Auckland, and went through a rebrand in 2019. After they had done this, they needed a new website to match.</strong></p>
    <p>They wanted a simple solution, one that listed their services while also encouraging click through to their existing booking platform. They were provided with an attractive and functional single-page site.</p>
    
    <p className="button"><a href="http://www.moodhair.nz/" target="_blank" rel="noreferrer">View Live Site</a></p>
`,
    technicalSpec:
      "<p>Built using WordPress. Optimised for page speed and accessibility.</p>",
    featureImage: `/mood-hair/mood.jpeg`,
    imageGallery: [
      {
        imageSrc: `/mood-hair/mood.jpeg`,
        imageAlt: `Mood hair studio one page website mock up.`,
      },
    ],
  },
  "liberate-it-branding": {
    name: "Liberate I.T.",
    subtitle: "Branding",
    projectBrief: `Create a new logo, variants, and full brand positioning.`,
    description: `<p>This project was part of a wider overhaul that was completed over 2018 - 2019 for Liberate I.T. and included many pieces of collateral.</p>
    
    <p>Liberate I.T. are a top level NetSuite Solution Provider in New Zealand and Australia. Prior to rebranding, their existing logo had a star included to dot the i with. They requested that this be experimented with in ways that keep a star element. After showing them a range of concepts, we all agreed on this direction.</p>
    
    <p>The brief stated that not only did the brand need to elevate the corporate identity of the company, but that it needed to be unique and different enough to truly stand out against their competitors. Because of this and a number of audience-specific considerations, the final product included a mix of inspirational imagery of children as superheros, alongside bright colouring with high contrast.</p>
    
    <p>These logo variations have been rolled out across a complete set of collateral.</p>`,
    technicalSpec: `<p>Designed using the Adobe Creative Suite.</p>`,
    featureImage: `/liberate/liberateMockUp.jpeg`,
    imageGallery: [
      {
        imageSrc: `/liberate/liberateMockUp.jpeg`,
        imageAlt: `Liberate I.T. Branding Design`,
      },
      {
        imageSrc: `/liberate/liberateRev.jpeg`,
        imageAlt: `Liberate I.T. Branding Reverse`,
      },
      {
        imageSrc: `/liberate/liberateBW.jpeg`,
        imageAlt: `Liberate I.T. Branding Design Greyscale`,
      },
      {
        imageSrc: `/liberate/liberateBWRev.jpeg`,
        imageAlt: `Liberate I.T. Branding Greyscale Reverse`,
      },
      {
        imageSrc: `/liberate/liberateBusinessCards.jpeg`,
        imageAlt: `Liberate I.T. Business Cards`,
      },
      {
        imageSrc: `/liberate/liberateStar.jpeg`,
        imageAlt: `Liberate I.T. Star Symbol`,
      },
      {
        imageSrc: `/liberate/liberateStar1col.jpeg`,
        imageAlt: `Liberate I.T. Star Single Colour`,
      },
    ],
  },
  "pupsiclepop-branding": {
    name: "PupsiclePop",
    subtitle: "Branding",
    projectBrief:
      "Design a logo and set of accompanying graphics for a Twitch TV streaming channel.",
    description: `<p>PupsiclePop is a Twitch TV channel devoted to music, featuring live performance in both singing and on the piano.</p>

    <p>Because of the nature of Twitch, it was an important aspect of the brief for the brand to be bright, memorable, and recognisable as the performer without featuring actual photography of them. This was both for privacy and also for wider printing purposes on related merchandise.</p>`,
    technicalSpec: "<p>Created using the Adobe Creative Suite.</p>",
    featureImage: `/pupsiclePop/pupsicleLogo.jpeg`,
    imageGallery: [
      {
        imageSrc: `/pupsiclePop/pupsicleLogo.jpeg`,
        imageAlt: `PupsiclePop Logo`,
      },
      {
        imageSrc: `/pupsiclePop/illustrationBook.jpeg`,
        imageAlt: `PupsiclePop Illustration Notebook Mock Up`,
      },
    ],
  },
  "miss-marie-branding": {
    name: "Miss Marie",
    subtitle: "Branding",
    projectBrief:
      "Create a main brand, as well as sub brands, for an online store",
    description: `<p>The main brand was to be in a pinup style, with sub-brands in steampunk and gothic styles.</p> 

    <p>As well as the full logos, there was to be a symbol version made for each style. These needed to be recognisable, and work on a range of media.</p>`,
    technicalSpec: "<p>Created using the Adobe Creative Suite.</p>",
    featureImage: `/miss-marie/logoMainWEB.jpeg`,
    imageGallery: [
      {
        imageSrc: `/miss-marie/logoMainWEB.jpeg`,
        imageAlt: `Pinup Miss Marie Logo`,
      },
      {
        imageSrc: `/miss-marie/logoGothWEB.jpeg`,
        imageAlt: `Gothic Miss Marie Logo`,
      },
      {
        imageSrc: `/miss-marie/logoSteamWEB.jpeg`,
        imageAlt: `Steampunk Miss Marie Logo`,
      },
      {
        imageSrc: `/miss-marie/iconsWEB.jpeg`,
        imageAlt: `Miss Marie Icons`,
      },
      {
        imageSrc: `/miss-marie/swingtagsWEB.jpeg`,
        imageAlt: `Miss Marie Swing Tags`,
      },
    ],
  },
  "anani-online-branding": {
    name: "Anani Online",
    subtitle: "Brand & Website Design",
    projectBrief:
      "Create branding suitable for usage on a website still to be built.",
    description: `<p>This project was to be completed in a way that would allow for the symbol to stand alone, and for both versions to be easily identifiable, while fitting within a standard customer expectation.</p>

    <p>It was made clear by the client that they wanted the branding to be minimal, clean and sophisticated. They also wanted it to be direct and to the point. Their aspiration was to be in the same league as the likes of Amazon, AliExpress, and other large retailers.</p> 
    
    <p>While the designs were accepted, Anani Online didn't end up coming to fruition as the client faced unexpected external factors.</p>`,
    technicalSpec: "<p>Created using the Adobe Creative Suite.</p>",
    featureImage: `/anani-online/anani-online-logo.jpeg`,
    imageGallery: [
      {
        imageSrc: `/anani-online/anani-online-logo.jpeg`,
        imageAlt: `Anani Online Logo`,
      },
      {
        imageSrc: `/anani-online/website-proof-on-tablet-scaled.jpeg`,
        imageAlt: `Anani Online Website Mock Up`,
      },
    ],
  },
  "fluffy-duck-branding": {
    name: "Fluffy Duck Art",
    subtitle: "Branding",
    projectBrief:
      "Create a logo for use on a range of media, that includes a feather.",
    description: `<p>The client wanted a feather, and had told me that they would like to include imagery related to fascinator hats.</p>

    <p>Because of these two factors, elements were included that reflected the client's personal style, as well as being appropriate for their audience. This meant including elegance, sophistication, and a level of wholesomeness, while also including femininity and individuality.</p> 
    
    <p>The branding needed to be unique enough to stand up against other artists marketing their work, and stand out enough to carry across to a public exhibition.</p> 
    
    <p>This branding was applied to social media and wider promotions, as well as the debut public exhibition for Heather Baird as an artist.</p>`,
    technicalSpec: "<p>Created using the Adobe Creative Suite.</p>",
    featureImage: `/fluffy-duck/fluffy-Duck-BrandingWEB.jpeg`,
    imageGallery: [
      {
        imageSrc: `/fluffy-duck/fluffy-Duck-BrandingWEB.jpeg`,
        imageAlt: `Fluffy Duck Art Logo`,
      },
    ],
  },
  "little-red-branding": {
    name: "Little Red",
    subtitle: "Branding",
    projectBrief: "Create branding suitable for usage across different media.",
    description: `<p>Little Red Photography wanted to be able to apply their brand to a range of media, both online and in print.</p> 

    <p>The branding was given elements which could be translated into other arrangements, and work at a range of sizes. This meant it could be used for embossing, as a seal, and in more standard applications.</p>
    
    <p>The brand was developed with specific variations to suit each suggested application, and media it would be produced upon. This came with a complete set of branding guidelines to explain the correct usage of each variant.</p>`,
    technicalSpec: "<p>Created using the Adobe Creative Suite.</p>",
    featureImage: `/little-red/little-red-logo-smallWEB.jpeg`,
    imageGallery: [
      {
        imageSrc: `/little-red/little-red-logo-smallWEB.jpeg`,
        imageAlt: `Little Red Logo`,
      },
      {
        imageSrc: `/little-red/little-red-logoWEB.jpeg`,
        imageAlt: `Little Red Logo`,
      },
    ],
  },
};

export default portfolioData;
