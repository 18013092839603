import React from "react";
import { Link } from "react-router-dom";
//images
import elephant from "../img/elephant.jpg";

//components
import ContactForm from "./contactForm";
import Testimonials from "./testimonials";
import wordPressIcon from "../img/wordpressIcon.png";
import devIcon from "../img/codeIcon.png";
import appIcon from "../img/mobileIcon.png";

const TechStack = () => {
 
  return (
   <div className="techStackContainer">
    <div className="mobileImage">
          <img src={elephant} alt="elephant" />
        </div>
    <div className="techStackInner">
      <div className="techStackFeature pageBody">
      <div className="techStackLeft">
      <h1>Tech Stack</h1>
      <p>Our tech stack is carefully curated to deliver innovative and high-performance digital solutions. Our front-end development relies on HTML5, CSS3, and JavaScript frameworks including React, ensuring engaging user experiences. On the back-end, we harness Node.js, Python, and other cutting edge languages for robust data management and complex business logic execution. We also offer products utilising content management systems such as WordPress, catering to versatile client needs.</p>

      <p>Our database management incorporates SQL and NoSQL databases, and we host our applications on secure environments, ensuring scalability. We prioritise version control with Git, collaborative tools like GitHub and Bitbucket, and implement rigorous testing and quality assurance. Security measures, analytics, and monitoring are integrated for comprehensive solutions, ensuring your digital project is in capable hands.</p>      

      <Link to="/contact"><p className="button">Contact Us</p></Link>

      </div>
      <div className="techStackRight">

      </div>
      </div>
      
      <div className="techStackBlocks">
        <div className="pageBody">
        <div className="techStackPageRow">
          <div className="techStackLeftBlock">
            <img src={wordPressIcon} alt="WordPress Icon" className="servicesIcon"/>
            <h2>WordPress Stack</h2>
            <p>When it comes to content management and website updates, we implement popular CMS platforms such as WordPress. These platforms empower our clients to easily manage their content, ensuring a user-friendly experience. Additionally, custom CMS solutions can be developed to suit specific client needs, offering flexibility and seamless content control.</p>
            <Link to="/websites"><p className="button">More Info</p></Link>
          </div>
          <div className="techStackCentreBlock">
          <img src={devIcon} alt="code icon" className="servicesIcon" />
          <h2>Custom Build Stack</h2>
            <p>We are committed to delivering visually stunning and responsive web and mobile applications. To achieve this, we leverage a variety of front-end technologies, including HTML5, CSS3, and JavaScript frameworks like React. These technologies enable us to create seamless user interfaces and immersive user experiences that cater to the modern demands of digital design.</p>
            <Link to="/websites"><p className="button">More Info</p></Link>
          </div>
          <div className="techStackRightBlock">
          <img src={appIcon} alt="mobile phone icon" className="servicesIcon" />
          <h2>App Development Stack</h2>
            <p>We utilise technologies including React Native, Swift, and Java to craft high-performance, cross-platform apps. Our back-end development leverages Node.js, and Python to ensure efficient data management and robust server-side operations. We offer seamless database solutions with SQL and NoSQL options like MySQL, PostgreSQL, MongoDB, and Firebase.</p>
            <Link to="/appdevelopment"><p className="button">More Info</p></Link>
          </div>
        </div>
       
          </div>
        </div>
      </div>
      <div className="homeContactContainer servicesContact">
      <div className="homeContactGrid pageBody">
        <div className="homeContactLeft">
          <h2>Contact Us</h2>
        <ContactForm />
        </div>
      <div className="homeContactRight">
        <h2>Testimonials</h2>
        <Testimonials />
      </div>
    </div>
    </div>
      </div>
  );
};

export default TechStack;
