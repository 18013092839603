import React from "react";

//images
import owl from "../img/owl.jpg";

//components
import ContactForm from "./contactForm";

const Contact = () => {
  

  return (

    <div className="contactContainer">
            <div className="mobileImage">
          <img src={owl} alt="owl" />
        </div>
      <div className="contactPageContainer">
    <div className="contactPage">
      <div className="contactPageLeft">
    <h1>Contact Us</h1>
    <p><strong>We would love to hear from you.</strong></p> 
    
    <p>Whether you have questions, feedback, or collaboration inquiries, we're here to help. Feel free to get in touch, and our team will be prompt in getting back to you.</p>
    <hr />
        <ContactForm />
        </div>
        <div className="contactPageRight">

        </div>

        
    </div>
    <div className="downloadBlockContact">
      <div className="pageBody">
      <div className="downloadGrid">
        <div className="downloadLeft">
        </div>
        <div className="downloadRight">
          <h2>Free Website Audit</h2>
          <p>If you have a website already, are you curious about how well it's performing and where it could be improved?</p> 
          
          <p>Let us take a deep dive into your digital footprint, analyzing every element from design and functionality to SEO and user experience. Our expert team will provide you with valuable insights and recommendations to enhance your website's effectiveness.</p>
          <p>Harness the power of data-driven decisions and make your online presence the best it can be. Get started with a free website audit today and pave the way for a more successful digital future.</p>

        </div>
      </div>
      </div>

        </div>
        </div>
        </div>


  );
};

export default Contact;
