import React from "react";
import { Link } from "react-router-dom";
//images
import appDesignIcon from "../img/mobileIcon.png";
import appDevIcon from "../img/codeIcon.png";
import swan from "../img/swan.jpg";

//components
import ContactForm from "./contactForm";
import Testimonials from "./testimonials";


const AppDevelopment = () => {
 
  return (
<div className="servicesContainer appDevContainer">
<div className="mobileImage">
          <img src={swan} alt="swan" />
        </div>
    <div className="servicesInner">
      <div className="servicesPageFeature pageBody">
      <div className="servicesPageLeft">
      <h1>App Development</h1>
      <p>App development is both an art and a science, where creativity converges with technical expertise to deliver mobile experiences that captivate and engage. We specialise in crafting high-performance, cross-platform applications that bridge the gap between functionality and user-friendliness. Utilising technologies like React Native, Swift, and Java, we ensure that your app runs seamlessly on both iOS and Android devices, making it accessible to a broad audience.</p>

      <p>Our back-end development, powered by Node.js, Python, and more, provides the backbone for data management, complex business logic execution, and server-side operations. Whether you're launching a mobile app for a startup, an e-commerce platform, or a feature-rich application, our app development process is agile and adaptable, catering to your specific project requirements. The result is not just an app but a digital solution that sets you apart in a competitive market. Your mobile vision, our technical mastery.</p>

      <Link to="/contact"><p className="button">Contact Us</p></Link>

      </div>
      <div className="servicesPageRight">

      </div>
      </div>
      
      <div className="servicesIndividualBlocks">
        <div className="pageBody">
        <div className="servicesIndividualRow">
          <div className="servicesIndividualLeftBlock">
            <img src={appDesignIcon} alt="mobile phone icon" className="servicesIcon" />
            <h2>App Design</h2>
            <p>The heart of a successful app lies in its design - a seamless blend of aesthetics and functionality that engages users and ensures a memorable experience. App design is a meticulous craft that defines the look, feel, and usability of the final product.</p>

            <p>Our design team is passionate about creating user-centric interfaces that resonate with your audience. We understand that the design goes far beyond visual aesthetics; it's about user interactions, intuitive navigation, and accessibility. With an in-depth understanding of mobile design principles and user experience (UX) best practices, we ensure that every pixel and interaction serves a purpose. We take the time to comprehend your brand's identity, your app's goals, and your target audience to create a design that not only meets but exceeds expectations.</p>

            <p>The app design process begins with wireframing and prototyping, allowing you to visualise the app's layout and functionality. We iterate and refine these concepts based on feedback and user testing, ensuring that the final design optimally caters to your users' needs. From choosing the right colour schemes, typography, and imagery to defining the user flow and touch interactions, our design team works harmoniously with the development team to bring your app vision to life.</p>

            <p>To achieve a design that resonates, we focus on the minutest details - from crafting compelling icons to ensuring responsive design that adapts seamlessly to various devices and screen sizes. We craft app designs that transform your vision into a digital masterpiece.</p>

          </div>
          <div className="servicesIndividualRightBlock">
          <img src={appDevIcon} alt="code icon" className="servicesIcon" />
          <h2>App Development</h2>
            <p>At the heart of our app development process is a commitment to delivering cutting-edge mobile solutions that go beyond mere functionality. Our team of seasoned app developers is dedicated to crafting applications that offer seamless user experiences, superior performance, and high scalability.</p>

            <p>The development journey begins with understanding your unique goals and objectives. We work closely with you to define the app's functionality, user flow, and technical requirements. Whether you're creating a startup app, an e-commerce platform, or a complex software solution, we utilise versatile technologies like React Native, Swift, and Java to ensure that your app performs exceptionally well on both iOS and Android devices.</p>

            <p>Back-end development, powered by Node.js, Python, and more, form the backbone of your app's data management and business logic execution. Security, stability, and scalability are paramount in our development process, and our experts employ best practices to ensure that your app is not only a technological marvel but also a secure and reliable digital asset.</p>

            <p>We understand that adaptability is key in the ever-evolving world of mobile app development. This is why our approach is flexible, allowing us to select the best technology stack to meet your project's specific needs. Whether it's a streamlined mobile app, a sophisticated web application, or a progressive web app, we have the expertise to make it a reality. With our app development, you can expect digital solutions that redefine possibilities, setting you apart in a competitive market. </p>

          </div>
        </div>
        </div>
      </div>
      <div className="homeContactContainer servicesContact">
      <div className="homeContactGrid pageBody">
        <div className="homeContactLeft">
          <h2>Contact Us</h2>
        <ContactForm />
        </div>
      <div className="homeContactRight">
        <h2>Testimonials</h2>
        <Testimonials />
      </div>
    </div>
    </div>
      </div>
      </div>
  );
};

export default AppDevelopment;


