import React from "react";
import { Link } from "react-router-dom";
import toTopIcon from "../img/toTopIcon.png";

const Footer = () => {
  return (
    <footer>
      <div className="footerContainer">
        <p>
          <span className="footerText">
            &copy; 2023 Rising Star Digital Ltd - All Rights Reserved
          </span>{" "}
          |{" "}
          <Link to="/privacy" style={{ textDecoration: "none" }}>
            Privacy Policy
          </Link>
          {" "}
          |{" "}
          <a href="#pageTop" className="toTopIcon"><img src={toTopIcon} alt="to top icon" /></a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
