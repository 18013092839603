import { React, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Portfolio from "./portfolio";
import Contact from "./contact";
import About from "./about";
import HomePage from "./home";
import PortfolioIndex from "./portfolioindex";
import PortfolioItem from "./portfolioItem";
import Privacy from "./privacy";
import Services from "./services";
import TechStack from "./techstack";
import Websites from "./websites";
import AppDevelopment from "./appDev";
import SEO from "./seo";
import Branding from "./branding";
import Copywriting from "./copywriting";
import EmailMarketing from "./emailMarketing";


const PageBody = () => {

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
} 

  return (
    <div>
      <Wrapper>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/websites" element={<Websites />} />
        <Route path="/appdevelopment" element={<AppDevelopment />} />
        <Route path="/seo" element={<SEO />} />
        <Route path="/branding" element={<Branding />} />
        <Route path="/copywriting" element={<Copywriting />} />
        <Route path="/emailmarketing" element={<EmailMarketing />} />
        <Route path="/techstack" element={<TechStack />} />
        <Route path="/portfolio" element={<Portfolio />}>
          <Route index element={<PortfolioIndex />} />
          <Route path=":slug" element={<PortfolioItem />} />
        </Route>
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      </Wrapper>
    </div>
  );
};

export default PageBody;

